import React from 'react';

function AboutUs() {
  return (
    <div style={{ padding: '50px', color: '#ffffff', backgroundColor: '#282c34', minHeight: '100vh' }}>
      
      {/* Section 1: Introduction */}
      <section style={{ maxWidth: '1200px', margin: '0 auto', marginBottom: '50px' }}>
        <h1 style={{ color: '#00bfa6', fontSize: '3rem', fontWeight: 'bold', textAlign: 'center' }}>About Us</h1>
        <p style={{ fontSize: '1.5rem', color: '#ffffff', textAlign: 'center', lineHeight: '1.8', margin: '30px 0' }}>
          Welcome to BluCollarBookings! We are dedicated to revolutionizing RV booking and work history tracking. Our platform connects customers with companies for efficient and transparent service bookings.
        </p>
      </section>

      {/* Section 2: Daniel's Story */}
      <section style={{ maxWidth: '1200px', margin: '0 auto', marginBottom: '50px', backgroundColor: '#333', padding: '40px', borderRadius: '8px' }}>
        <h2 style={{ fontSize: '2rem', color: '#00bfa6', textAlign: 'left', fontWeight: 'bold' }}>Our Founder - Daniel King</h2>
        <p style={{ fontSize: '1.3rem', color: '#ffffff', textAlign: 'left', lineHeight: '1.8', margin: '20px 0' }}>
          Daniel King, the CEO of the company, and RV Technician of 10 years, has grown up in shops all of his life. From construction, marine, trailers, and RVs—from some of the largest companies in the United States to some of the smallest.
          After years of being in shops, there is always one thing for sure, and that is: "You never know what is going on with your RV once you hand over your keys." Daniel is here to provide assurance and peace of mind that your RV will be safe 
          with any of the companies working alongside BluCollarBookings.
        </p>
      </section>

      {/* Section 3: What is BluCollarBookings */}
      <section style={{ maxWidth: '1200px', margin: '0 auto', marginBottom: '50px' }}>
        <h2 style={{ fontSize: '2.5rem', color: '#00bfa6', textAlign: 'center', fontWeight: 'bold' }}>What is BluCollarBookings?</h2>
        <p style={{ fontSize: '1.3rem', color: '#ffffff', textAlign: 'center', lineHeight: '1.8', margin: '20px 0' }}>
          BluCollarBookings is a series of mobile apps and a website for customers to book appointments, view who worked on their RV, see what happened, view photos of each job, and most importantly, view your work history anywhere with internet access.
          Our platform helps weed out bad shops and mobile mechanics by using two forms of verification to showcase professionalism: “Trusted” and “Verified” companies.
        </p>
      </section>

      {/* Section 4: Trusted Companies */}
      <section style={{ maxWidth: '1200px', margin: '0 auto', marginBottom: '50px', backgroundColor: '#333', padding: '40px', borderRadius: '8px' }}>
        <h2 style={{ fontSize: '2rem', color: '#00bfa6', textAlign: 'left', fontWeight: 'bold' }}>Trusted vs Verified Companies</h2>
        <p style={{ fontSize: '1.3rem', color: '#ffffff', textAlign: 'left', lineHeight: '1.8', margin: '20px 0' }}>
          <strong>Trusted Companies</strong> are those trusted by an X amount of good reviews towards the business. Once a bad review is placed for a company, it becomes harder to regain their trusted status.
        </p>
        <p style={{ fontSize: '1.3rem', color: '#ffffff', textAlign: 'left', lineHeight: '1.8', margin: '20px 0' }}>
          <strong>Verified Companies</strong> are those where Daniel and Shyanne spend a month with the company. Shyanne will guide service writers in the correct direction, showcasing the app, while Daniel works hands-on with every technician to train, help, and verify the company’s worthiness for BluCollarBookings.
        </p>
      </section>
    </div>
  );
}

export default AboutUs;
