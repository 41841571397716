import React, { useState, useEffect } from 'react';
import { getDatabase, ref, set, get, query, orderByKey, startAt, limitToFirst } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useNavigate } from 'react-router-dom';
import './ticketCreation.css';  // Import custom CSS for styling

const TicketCreation = () => {
  const [customerFirstName, setCustomerFirstName] = useState('');
  const [customerLastName, setCustomerLastName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [latLng, setLatLng] = useState(null);
  const [rvMake, setRvMake] = useState('');
  const [rvModel, setRvModel] = useState('');
  const [rvYear, setRvYear] = useState('');
  const [rvVin, setRvVin] = useState('');
  const [axleAmount, setAxleAmount] = useState('1');
  const [jobLines, setJobLines] = useState([]);
  const [problem, setProblem] = useState('');
  const [estimatedTime, setEstimatedTime] = useState(0);
  const [estimate, setEstimate] = useState(false);
  const [isVinFound, setIsVinFound] = useState(false);
  const [vinSuggestions, setVinSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const navigate = useNavigate();
  const auth = getAuth();
  const database = getDatabase();

  const handleVinInputChange = (e) => {
    const vinInput = e.target.value;
    setRvVin(vinInput);

    if (vinInput.length > 2) {
      const rvPoolRef = query(
        ref(database, 'users/RVPool'),
        orderByKey(),
        startAt(vinInput),
        limitToFirst(5)
      );

      get(rvPoolRef).then((snapshot) => {
        if (snapshot.exists()) {
          const suggestions = [];
          snapshot.forEach((childSnapshot) => {
            suggestions.push(childSnapshot.key);
          });
          setVinSuggestions(suggestions);
        } else {
          setVinSuggestions([]);
        }
      }).catch((error) => {
        console.error('Error fetching VIN suggestions:', error);
      });
    } else {
      setVinSuggestions([]);
    }
  };

 const handleVinSelect = (selectedVin) => {
    setRvVin(selectedVin);
    setVinSuggestions([]);

    const rvRef = ref(database, `users/RVPool/${selectedVin}`);
    get(rvRef).then((snapshot) => {
        if (snapshot.exists()) {
            const rvData = snapshot.val();
            setRvMake(rvData.make);
            setRvModel(rvData.model);
            setRvYear(rvData.year);
            setAxleAmount(rvData.axleAmount);
            setIsVinFound(true);

            // Fetch customer details if RV is assigned
            if (rvData.assigned) {
                const customerRef = ref(database, `users/customers/${rvData.customerUUID}/customerDetails`);
                get(customerRef).then((customerSnapshot) => {
                    if (customerSnapshot.exists()) {
                        const customerData = customerSnapshot.val();
                        setCustomerFirstName(customerData.firstName || '');
                        setCustomerLastName(customerData.lastName || '');
                        setCustomerEmail(customerData.email || '');
                        setCustomerPhone(customerData.phone || '');
                        setCustomerAddress(customerData.address || '');
                    }
                }).catch((error) => {
                    console.error('Error fetching customer details:', error);
                });
            }
        }
    }).catch((error) => {
        console.error('Error fetching RV details:', error);
    });
};


  const handleSelectAddress = async (address) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);
    setCustomerAddress(address);
    setLatLng(latLng);
  };

  const handleAddJobLine = () => {
    setJobLines((prevJobs) => [
      ...prevJobs,
      { problem, estimatedTime, estimate }
    ]);
    setProblem('');
    setEstimatedTime(0);
    setEstimate(false);
  };

  const handleDeleteJobLine = (index) => {
    setJobLines((prevJobs) => prevJobs.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const user = auth.currentUser;

    if (user) {
      const companyId = user.uid;
      const ticketId = Math.floor(100000 + Math.random() * 900000).toString();
      const currentDateTime = new Date();
      const formattedDate = currentDateTime.toISOString().slice(0, 19).replace('T', ' ');

      const ticketData = {
        customerDetails: {
          firstName: customerFirstName,
          lastName: customerLastName,
          email: customerEmail,
          phone: customerPhone,
          address: customerAddress,
          coordinates: latLng,
          profileImageUrl: '',
        },
        customerRV: {
          make: rvMake,
          model: rvModel,
          year: rvYear,
          vin: rvVin,
          axleAmount,
          rvImage: ''
        },
        activeTimestamp: Date.now(),
        pendingTimestamp: formattedDate,
        ticketSettings: {
          completedServiceTickets: false
        },
        Jobs: jobLines.map((job, index) => ({
          problem: job.problem,
          cause: '',
          correction: '',
          estimatedTime: job.estimatedTime,
          estimate: job.estimate
        })).reduce((acc, job, index) => {
          acc[index + 1] = job;
          return acc;
        }, {}),
      };

      setLoading(true);

      set(ref(database, `users/companies/${companyId}/ticketManager/activeServiceTickets/${ticketId}`), ticketData)
        .then(() => {
          setSuccessMessage('Ticket created successfully!');
          navigate('/dashboard');
        })
        .catch((error) => {
          console.error('Error creating ticket:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="ticket-creation-container">
      <h2>Create a New Ticket</h2>

      {loading ? (
        <div className="loading-container">Creating ticket, please wait...</div>
      ) : (
        <>
          {successMessage && <div className="success-message">{successMessage}</div>}
          <form onSubmit={handleSubmit} className="ticket-form">
            <div className="form-section">
              <h3>Customer Information</h3>
              <input
                type="text"
                placeholder="First Name"
                value={customerFirstName}
                onChange={(e) => setCustomerFirstName(e.target.value)}
                required
                className="form-input"
              />
              <input
                type="text"
                placeholder="Last Name"
                value={customerLastName}
                onChange={(e) => setCustomerLastName(e.target.value)}
                required
                className="form-input"
              />
              <input
                type="email"
                placeholder="Email"
                value={customerEmail}
                onChange={(e) => setCustomerEmail(e.target.value)}
                required
                className="form-input"
              />
              <input
                type="tel"
                placeholder="Phone Number"
                value={customerPhone}
                onChange={(e) => setCustomerPhone(e.target.value)}
                required
                className="form-input"
              />
              <PlacesAutocomplete
                value={customerAddress}
                onChange={setCustomerAddress}
                onSelect={handleSelectAddress}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: 'Search Customer Address...',
                        className: 'form-input',
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>

            <div className="form-section">
              <h3>RV Information</h3>
              <input
                type="text"
                placeholder="RV VIN"
                value={rvVin}
                onChange={handleVinInputChange}
                required
                className="form-input"
              />
              {vinSuggestions.length > 0 && (
                <ul className="vin-suggestions">
                  {vinSuggestions.map((suggestion) => (
                    <li key={suggestion} onClick={() => handleVinSelect(suggestion)}>
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}
              <input
                type="text"
                placeholder="RV Make"
                value={rvMake}
                onChange={(e) => setRvMake(e.target.value)}
                required={!isVinFound}
                className="form-input"
                disabled={isVinFound}
              />
              <input
                type="text"
                placeholder="RV Model"
                value={rvModel}
                onChange={(e) => setRvModel(e.target.value)}
                required={!isVinFound}
                className="form-input"
                disabled={isVinFound}
              />
              <input
                type="text"
                placeholder="RV Year"
                value={rvYear}
                onChange={(e) => setRvYear(e.target.value)}
                required={!isVinFound}
                className="form-input"
                disabled={isVinFound}
              />
              <div className="form-dropdown">
                <label>Axle Amount:</label>
                <select
                  value={axleAmount}
                  onChange={(e) => setAxleAmount(e.target.value)}
                  className="dropdown"
                  required
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="Class C">Class C</option>
                  <option value="Class A (Gas)">Class A (Gas)</option>
                  <option value="Class A (Diesel)">Class A (Diesel)</option>
                  <option value="Super B">Super B</option>
                </select>
              </div>
            </div>

            <div className="form-section">
              <h3>Job Lines</h3>
              <div className="job-line-row">
                <input
                  type="text"
                  placeholder="Enter Problem"
                  value={problem}
                  onChange={(e) => setProblem(e.target.value)}
                  className="form-input job-input"
                />
                <div className="time-input">
                  <label>Estimated Time</label>
                  <div className="time-buttons">
                    <button
                      type="button"
                      onClick={() => setEstimatedTime((prev) => Math.max(prev - 0.25, 0))}
                      className="time-button"
                    >
                      -
                    </button>
                    <input
                      type="text"
                      value={estimatedTime}
                      onChange={(e) => setEstimatedTime(parseFloat(e.target.value) || 0)}
                      className="time-text"
                      readOnly
                    />
                    <button
                      type="button"
                      onClick={() => setEstimatedTime((prev) => prev + 0.25)}
                      className="time-button"
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="estimate-selection">
                  <label>Estimate?</label>
                  <select
                    value={estimate}
                    onChange={(e) => setEstimate(e.target.value === 'true')}
                    className="dropdown"
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                </div>
              </div>
              <button type="button" onClick={handleAddJobLine} className="add-job-button">Add Job</button>
              <ul className="job-list">
                {jobLines.map((job, index) => (
                  <li key={index} className="job-item">
                    <p>Problem: {job.problem}</p>
                    <p>Estimated Time: {job.estimatedTime} hours</p>
                    <p>Estimate: {job.estimate ? 'Yes' : 'No'}</p>
                    <button type="button" onClick={() => handleDeleteJobLine(index)} className="delete-job-button">Delete</button>
                  </li>
                ))}
              </ul>
            </div>

            <button type="submit" className="submit-button">Create Ticket</button>
          </form>
        </>
      )}
    </div>
  );
};

export default TicketCreation;
