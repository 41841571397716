import React, { useState, useEffect } from 'react';
import { database } from '../firebase'; 
import { ref, onValue, remove, set } from 'firebase/database'; 
import { getAuth } from 'firebase/auth';
import { useParams } from 'react-router-dom';
import './completedServiceTicket.css';  // Custom CSS

const CompletedServiceTicket = () => {
  const { ticketId } = useParams();  
  const [parts, setParts] = useState([]);
  const [jobLines, setJobLines] = useState([]);
  const [totalLaborBalance, setTotalLaborBalance] = useState(0);
  const [totalPartsAmount, setTotalPartsAmount] = useState(0.0);
  const [shopSupplies, setShopSupplies] = useState(0.0);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [rvDetails, setRvDetails] = useState(null);
  const [laborRate, setLaborRate] = useState(0); 
  const auth = getAuth();
  const [companyId, setCompanyId] = useState('');

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userCompanyIdRef = ref(database, `users/companies/${user.uid}/companyDetails`);
      onValue(userCompanyIdRef, (snapshot) => {
        const companyDetails = snapshot.val();
        if (companyDetails) {
          setCompanyId(companyDetails.companyUUID);
          setLaborRate(companyDetails.laborRate || 0); 
        }
      });
    }
  }, [auth]);

  useEffect(() => {
    if (companyId && ticketId) {
      const partsRef = ref(database, `users/companies/${companyId}/ticketManager/completedServiceTickets/${ticketId}/Parts`);
      onValue(partsRef, (snapshot) => {
        const partsData = [];
        let total = 0;

        snapshot.forEach((childSnapshot) => {
          const partKey = childSnapshot.key;
          const part = childSnapshot.val();

          if (partKey !== 'ShopSupplies' && partKey !== 'TotalPartsAmount') {
            if (part.perPartAmount && part.quantity) {
              total += part.perPartAmount * part.quantity;
            }
            partsData.push(part);
          }
        });

        setParts(partsData);
        setTotalPartsAmount(total);
        setShopSupplies(total * 0.1);  
      });

      const jobLinesRef = ref(database, `users/companies/${companyId}/ticketManager/completedServiceTickets/${ticketId}/Jobs`);
      onValue(jobLinesRef, (snapshot) => {
        const jobLinesData = [];
        let totalBalance = 0; 

        snapshot.forEach((childSnapshot) => {
          const jobKey = childSnapshot.key;
          const jobLine = { key: childSnapshot.key, ...childSnapshot.val() };

          if (jobKey !== 'TotalLaborBalance') {
            jobLine.totalBalance = (jobLine.estimatedTime || 0) * laborRate;
            totalBalance += jobLine.totalBalance;  
            jobLinesData.push(jobLine);
          }
        });

        setJobLines(jobLinesData);
        setTotalLaborBalance(totalBalance);  
      });

      const customerRef = ref(database, `users/companies/${companyId}/ticketManager/completedServiceTickets/${ticketId}/customerDetails`);
      onValue(customerRef, (snapshot) => {
        setCustomerDetails(snapshot.val());
      });

      const rvRef = ref(database, `users/companies/${companyId}/ticketManager/completedServiceTickets/${ticketId}/customerRV`);
      onValue(rvRef, (snapshot) => {
        setRvDetails(snapshot.val());
      });
    }
  }, [companyId, ticketId, laborRate]);

  const formatEstimatedTime = (hours) => {
    const h = Math.floor(hours);
    const m = Math.round((hours - h) * 60);
    return `${h}h${m > 9 ? m : '0' + m}m`;
  };

  const calculateTotalBalance = () => {
    return shopSupplies + totalPartsAmount + totalLaborBalance;
  };

const handleCompleteTicket = () => {
  if (!companyId || !ticketId || !rvDetails?.vin) return;

  const completedTicketRef = ref(database, `users/companies/${companyId}/ticketManager/completedServiceTickets/${ticketId}`);
  const rvWorkHistoryRef = ref(database, `users/RVPool/${rvDetails.vin}/workHistory/${ticketId}`);

  // Helper function to format date into standard non-military time
  const formatDate = (date) => {
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const isPM = hours >= 12;
    hours = hours % 12 || 12; // Convert to 12-hour format
    const ampm = isPM ? 'PM' : 'AM';
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
  };

  const completedTime = formatDate(new Date()); // Get the current time in standard format
  const totalBalance = calculateTotalBalance(); // Calculate total balance (labor, parts, and supplies)

  // Fetch the completed ticket data
  onValue(completedTicketRef, (snapshot) => {
    const ticketData = snapshot.val();

    if (ticketData) {
      // Add completedTimestamp and TotalBalance fields
      ticketData.completedTimestamp = completedTime;
      ticketData.TotalBalance = totalBalance;

      // Save the ticket data with completedTimestamp and TotalBalance to work history
      set(rvWorkHistoryRef, ticketData)
        .then(() => {
          // Remove the ticket from completedServiceTickets
          remove(completedTicketRef)
            .then(() => {
              console.log('Ticket successfully saved to work history with completedTimestamp, TotalBalance, and removed from completedServiceTickets.');
            })
            .catch((error) => {
              console.error('Error removing from completedServiceTickets:', error);
            });
        })
        .catch((error) => {
          console.error('Error saving to work history:', error);
        });
    }
  }, {
    onlyOnce: true
  });
};


  return (
    <div className="completed-ticket-container">
      <h2 className="completed-ticket-id">Ticket #{ticketId}</h2>

      <div className="completed-ticket-content-container">
        <div className="completed-ticket-left-container">
          <h3>Customer Information</h3>
          {customerDetails ? (
            <div>
              <p><strong>Name:</strong> {customerDetails.firstName} {customerDetails.lastName}</p>
              <p><strong>Email:</strong> {customerDetails.email}</p>
              <p><strong>Phone:</strong> {customerDetails.phone}</p>
              <p><strong>Address:</strong> {customerDetails.address}</p>
              <img src={customerDetails.profileImageUrl} alt="Customer Profile" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
            </div>
          ) : (
            <p>Loading customer details...</p>
          )}
        </div>

        <div className="completed-ticket-right-container">
          <h3>RV Information</h3>
          {rvDetails ? (
            <div>
              <p><strong>Make:</strong> {rvDetails.make}</p>
              <p><strong>Model:</strong> {rvDetails.model}</p>
              <p><strong>Year:</strong> {rvDetails.year}</p>
              <p><strong>VIN:</strong> {rvDetails.vin}</p>
              <p><strong>Axle Amount:</strong> {rvDetails.axleAmount}</p>
              <img src={rvDetails.rvImage} alt="RV" style={{ width: '150px', height: '100px', borderRadius: '8px' }} />
            </div>
          ) : (
            <p>Loading RV details...</p>
          )}
        </div>
      </div>

      <h3 className="completed-ticket-parts-header">Parts for Ticket #{ticketId}</h3>
      <div className="completed-ticket-parts-list-container">
        <div className="completed-ticket-parts-list">
          {parts.length === 0 ? (
            <p>No parts added yet.</p>
          ) : (
            parts.map((part, index) => (
              <div key={index} className="completed-ticket-part-item">
                <span><strong>Qty:</strong> {part.quantity}</span>
                <span><strong>Description:</strong> {part.description}</span>
                <span><strong>Per Item Price:</strong> ${part.perPartAmount?.toFixed(2)}</span>
                <span><strong>Total Amount:</strong> ${part.totalAmount?.toFixed(2)}</span>
              </div>
            ))
          )}
        </div>
        <div className="completed-ticket-parts-totals">
          <p>Shop Supplies: ${shopSupplies.toFixed(2)}</p>
          <p>Total Parts Amount: ${totalPartsAmount.toFixed(2)}</p>
        </div>
      </div>

      <h3 className="completed-ticket-job-lines-header">Job Lines</h3>
      <div className="completed-ticket-job-lines-container">
        {jobLines.length === 0 ? (
          <p>No job lines available.</p>
        ) : (
          jobLines.map((job, index) => (
            <div
              key={index}
              className="completed-ticket-job-line-item"
              style={{
                backgroundColor: job.estimate ? '#fff9c4' : 'white',
                padding: '10px',
                borderRadius: '5px',
                marginBottom: '10px',
                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
              }}
            >
              <p><strong>Problem:</strong> {job.problem}</p>
              <p><strong>Cause:</strong> {job.cause}</p>
              <p><strong>Correction:</strong> {job.correction}</p>
              <p><strong>Estimated Time:</strong> {formatEstimatedTime(job.estimatedTime)}</p>
              <p><strong>Balance:</strong> ${job.totalBalance?.toFixed(2)}</p>
            </div>
          ))
        )}

        <div className="completed-ticket-totals-section" style={{ textAlign: 'right' }}>
          <h3>Total Labor Balance: ${totalLaborBalance.toFixed(2)}</h3>
        </div>
      </div>

      <div className="completed-ticket-totals-section" style={{ textAlign: 'right' }}>
        <h3>Total Balance: ${calculateTotalBalance().toFixed(2)}</h3>
      </div>

      <button className="completed-ticket-complete-btn" onClick={handleCompleteTicket}>
        Complete Ticket
      </button>
    </div>
  );
};

export default CompletedServiceTicket;
