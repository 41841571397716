import React, { useState, useEffect } from 'react';
import { database } from '../firebase'; 
import { ref, onValue, set, push, update, remove } from 'firebase/database'; 
import { getAuth } from 'firebase/auth';
import { useParams } from 'react-router-dom';
import './approvalServiceTicket.css'; // Import the custom CSS for ApprovalServiceTickets


const ApprovalServiceTickets = () => {
  const { ticketId } = useParams();  // Retrieve ticketId from the URL
  const [parts, setParts] = useState([]);
  const [jobLines, setJobLines] = useState([]);
  const [quantity, setQuantity] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [totalPartsAmount, setTotalPartsAmount] = useState(0.0);
  const [shopSupplies, setShopSupplies] = useState(0.0);
  const [expandedJobIndex, setExpandedJobIndex] = useState(null); // Track expanded job index
  const [customerDetails, setCustomerDetails] = useState(null);
  const [rvDetails, setRvDetails] = useState(null);
  const [technicians, setTechnicians] = useState([]);
  const [selectedTechnician, setSelectedTechnician] = useState('');
  const [assignedTechnician, setAssignedTechnician] = useState('');
  const [laborRate, setLaborRate] = useState(0); // State for labor rate

  const auth = getAuth();
  const [companyId, setCompanyId] = useState('');

  // Fetch companyUUID and laborRate based on the logged-in user's UID
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userCompanyIdRef = ref(database, `users/companies/${user.uid}/companyDetails`);
      
      onValue(userCompanyIdRef, (snapshot) => {
        const companyDetails = snapshot.val();
        if (companyDetails) {
          setCompanyId(companyDetails.companyUUID);
          setLaborRate(companyDetails.laborRate || 0); // Set laborRate from companyDetails
        }
      });
    }
  }, [auth]);

  // Fetch parts, job lines, customer, RV details, and technicians for the specific ticket
  useEffect(() => {
    if (companyId && ticketId) {
      const partsRef = ref(database, `users/companies/${companyId}/ticketManager/approvalServiceTickets/${ticketId}/Parts`);
      onValue(partsRef, (snapshot) => {
        const partsData = [];
        let total = 0;

        snapshot.forEach((childSnapshot) => {
          const partKey = childSnapshot.key;
          const part = childSnapshot.val();

          // Exclude entries for shopSupplies and totalPartsAmount, only push actual parts
          if (partKey !== 'ShopSupplies' && partKey !== 'TotalPartsAmount') {
            if (part.perPartAmount && part.quantity) {
              total += part.perPartAmount * part.quantity;
            }
            partsData.push(part);
          }
        });

        setParts(partsData);
        setTotalPartsAmount(total);
        setShopSupplies(total * 0.1);  // 10% of the total amount
      });

      const jobLinesRef = ref(database, `users/companies/${companyId}/ticketManager/approvalServiceTickets/${ticketId}/Jobs`);
      onValue(jobLinesRef, (snapshot) => {
        const jobLinesData = [];
        snapshot.forEach((childSnapshot) => {
          const jobLine = { key: childSnapshot.key, ...childSnapshot.val() }; // Store key for updates
          // Calculate totalBalance
          jobLine.totalBalance = jobLine.estimatedTime ? jobLine.estimatedTime * laborRate : 0; // Set totalBalance based on estimatedTime
          jobLinesData.push(jobLine);
        });
        setJobLines(jobLinesData);
      });

      // Fetch customer and RV details
      const customerRef = ref(database, `users/companies/${companyId}/ticketManager/approvalServiceTickets/${ticketId}/customerDetails`);
      onValue(customerRef, (snapshot) => {
        setCustomerDetails(snapshot.val());
      });

      const rvRef = ref(database, `users/companies/${companyId}/ticketManager/approvalServiceTickets/${ticketId}/customerRV`);
      onValue(rvRef, (snapshot) => {
        setRvDetails(snapshot.val());
      });

      // Fetch technicians
      const employeesRef = ref(database, `users/companies/${companyId}/Employees`);
      onValue(employeesRef, (snapshot) => {
        const technicianList = [];
        snapshot.forEach((childSnapshot) => {
          const employee = childSnapshot.val();
          if (employee.roles && employee.roles.isTechnician) {
            technicianList.push({ id: childSnapshot.key, ...employee });
          }
        });
        setTechnicians(technicianList);
      });

      // Fetch assigned technician
      const ticketRef = ref(database, `users/companies/${companyId}/ticketManager/approvalServiceTickets/${ticketId}`);
      onValue(ticketRef, (snapshot) => {
        const ticketData = snapshot.val();
        if (ticketData?.assignedTechnician) {
          setAssignedTechnician(ticketData.assignedTechnician);
        }
      });
    }
  }, [companyId, ticketId, laborRate]);

  // Function to format time in hours and minutes
  const formatTime = (timeInMillis) => {
    const totalMinutes = Math.floor(timeInMillis / (1000 * 60));
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h${String(minutes).padStart(2, '0')}m`;
  };

  // Calculate total time spent for job line
  const calculateTotalTimeSpent = (sessions) => {
    let totalTimeMillis = 0;
    if (sessions) {
      Object.values(sessions).forEach((session) => {
        const clockInTime = session.clockInTime || 0;
        const clockOutTime = session.clockOutTime || 0;
        totalTimeMillis += (clockOutTime - clockInTime);
      });
    }
    return totalTimeMillis;
  };

  // Approve suggested time
  const handleApproveTime = (jobKey, suggestedTime) => {
    const jobRef = ref(database, `users/companies/${companyId}/ticketManager/approvalServiceTickets/${ticketId}/Jobs/${jobKey}`);
    update(jobRef, { 
      estimatedTime: suggestedTime,
      estimate: false, // Change estimate to false
      suggestedTime: null // Remove suggestedTime
    }).then(() => {
      alert("Estimated time approved successfully.");
    }).catch((error) => {
      console.error("Error approving estimated time:", error);
    });
  };

  // Assign a technician to the ticket
  const handleAssignTechnician = () => {
    if (!selectedTechnician || !companyId || !ticketId) {
      alert("Please select a technician.");
      return;
    }

    const ticketRef = ref(database, `users/companies/${companyId}/ticketManager/approvalServiceTickets/${ticketId}`);
    update(ticketRef, {
      assignedTechnician: selectedTechnician  // Save the technician's ID in the ticket
    }).then(() => {
      alert("Technician assigned successfully.");
    }).catch((error) => {
      console.error("Error assigning technician:", error);
    });
  };

  // Release the assigned technician
  const handleReleaseTechnician = () => {
    if (!companyId || !ticketId) return;

    const ticketRef = ref(database, `users/companies/${companyId}/ticketManager/approvalServiceTickets/${ticketId}/assignedTechnician`);
    
    // Remove the assigned technician field
    remove(ticketRef).then(() => {
      alert("Technician released successfully.");
      setAssignedTechnician('');  // Clear the UI
    }).catch((error) => {
      console.error("Error releasing technician:", error);
    });
  };

  // Add a new part to the selected ticket
  const handleAddPart = () => {
    if (!companyId || !ticketId) return;

    const validQuantity = parseFloat(quantity);
    const validPrice = parseFloat(price);
    if (isNaN(validQuantity) || isNaN(validPrice) || validQuantity <= 0 || validPrice <= 0) return;

    const newPart = { quantity: validQuantity, description, perPartAmount: validPrice, totalAmount: validQuantity * validPrice };

    const partsRef = ref(database, `users/companies/${companyId}/ticketManager/approvalServiceTickets/${ticketId}/Parts`);
    const newPartRef = push(partsRef);
    set(newPartRef, newPart).then(() => {
      setQuantity('');
      setDescription('');
      setPrice('');
    });

    // Save ShopSupplies and TotalPartsAmount inside the Parts directory
    update(partsRef, {
      TotalPartsAmount: totalPartsAmount + validQuantity * validPrice,
      ShopSupplies: (totalPartsAmount + validQuantity * validPrice) * 0.1,
    });
  };

  // Display job photos
  const renderJobPhotos = (photos) => {
    if (!photos) return null;

    return Object.entries(photos).map(([key, photoUrl]) => (
      <img
        key={key}
        src={photoUrl}
        alt="Job Photo"
        style={{ width: '100px', height: '100px', marginRight: '10px', borderRadius: '8px' }}
      />
    ));
  };

  // Toggle job line expansion to edit cause and correction
  const handleJobToggle = (index) => {
    setExpandedJobIndex(expandedJobIndex === index ? null : index);
  };

  // Function to handle real-time updates for Cause and Correction in Firebase
  const handleJobUpdate = (jobKey, field, value) => {
    const jobRef = ref(database, `users/companies/${companyId}/ticketManager/approvalServiceTickets/${ticketId}/Jobs/${jobKey}`);
    update(jobRef, { [field]: value }).catch((error) => {
      console.error("Error updating job line:", error);
    });
  };

  // Approve Ticket Function
  const handleApproveTicket = () => {
    if (!companyId || !ticketId) return;

    const approvedTicketRef = ref(database, `users/companies/${companyId}/ticketManager/activeServiceTickets/${ticketId}`);
    const approvalTicketRef = ref(database, `users/companies/${companyId}/ticketManager/approvalServiceTickets/${ticketId}`);

    // Move the ticket from approval to active service tickets
    onValue(approvalTicketRef, (snapshot) => {
      const ticketData = snapshot.val();
      if (ticketData) {
        // Set the ticket data in activeServiceTickets
        set(approvedTicketRef, ticketData)
          .then(() => {
            // Remove the ticket from approvalServiceTickets after moving
            remove(approvalTicketRef).then(() => {
              alert("Ticket approved successfully.");
            }).catch((error) => {
              console.error("Error removing ticket from approval service tickets:", error);
            });
          })
          .catch((error) => {
            console.error("Error approving ticket:", error);
          });
      } else {
        alert("No ticket data found.");
      }
    });
  };

  return (
    <div className="service-ticket-container">
      <h2 className="ticket-id">Ticket #{ticketId}</h2>

      {/* Assigned Technician */}
      <div className="assigned-technician">
        <h3>Assigned Technician:</h3>
        {assignedTechnician ? (
          <>
            <p>{technicians.find(tech => tech.id === assignedTechnician)?.firstName || 'Unknown Technician'}</p>
            <button onClick={handleReleaseTechnician}>Release Technician</button>
          </>
        ) : (
          <p>No technician assigned yet.</p>
        )}
      </div>

      {/* Technician Assignment Dropdown */}
      <div className="technician-selection">
        <select
          value={selectedTechnician}
          onChange={(e) => setSelectedTechnician(e.target.value)}
        >
          <option value="">Select Technician</option>
          {technicians.map((technician) => (
            <option key={technician.id} value={technician.id}>
              {technician.firstName} {technician.lastName}
            </option>
          ))}
        </select>
        <button onClick={handleAssignTechnician}>Assign Technician</button>
      </div>

      {/* Top Section: Customer and RV Information */}
      <div className="content-container">
        {/* Left Side: Customer Information */}
        <div className="left-container">
          <h3>Customer Information</h3>
          {customerDetails ? (
            <div>
              <p><strong>Name:</strong> {customerDetails.firstName} {customerDetails.lastName}</p>
              <p><strong>Email:</strong> {customerDetails.email}</p>
              <p><strong>Phone:</strong> {customerDetails.phone}</p>
              <p><strong>Address:</strong> {customerDetails.address}</p>
              <img src={customerDetails.profileImageUrl} alt="Customer Profile" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
            </div>
          ) : (
            <p>Loading customer details...</p>
          )}
        </div>

        {/* Right Side: RV Information */}
        <div className="right-container">
          <h3>RV Information</h3>
          {rvDetails ? (
            <div>
              <p><strong>Make:</strong> {rvDetails.make}</p>
              <p><strong>Model:</strong> {rvDetails.model}</p>
              <p><strong>Year:</strong> {rvDetails.year}</p>
              <p><strong>VIN:</strong> {rvDetails.vin}</p>
              <p><strong>Axle Amount:</strong> {rvDetails.axleAmount}</p>
              <img src={rvDetails.rvImage} alt="RV" style={{ width: '150px', height: '100px', borderRadius: '8px' }} />
            </div>
          ) : (
            <p>Loading RV details...</p>
          )}
        </div>
      </div>

      {/* Remaining content like Parts and Job Lines */}
      <div className="content-container">
        {/* Left Side - Add Part Form */}
        <div className="left-container">
          <h3>Add New Part</h3>
          <input
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            placeholder="Quantity"
          />
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
          />
          <input
            type="number"
            step="0.01"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeholder="Per Item Price"
          />
          <button onClick={handleAddPart}>Add Part</button>

          <div className="totals-section">
            <h3>Shop Supplies: ${shopSupplies.toFixed(2)}</h3>
            <h3>Total Parts Amount: ${totalPartsAmount.toFixed(2)}</h3>
          </div>
        </div>

        {/* Right Side - Parts List */}
        <div className="right-container">
          <h3>Parts for Ticket #{ticketId}</h3>
          <div className="parts-list">
            {parts.length === 0 ? (
              <p>No parts added yet.</p>
            ) : (
              parts.map((part, index) => (
                <div key={index} className="part-item">
                  <span><strong>Qty:</strong> {part.quantity}</span>
                  <span><strong>Description:</strong> {part.description}</span>
                  <span><strong>Per Item Price:</strong> ${part.perPartAmount?.toFixed(2)}</span>
                  <span><strong>Total Amount:</strong> ${part.totalAmount?.toFixed(2)}</span>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      {/* Bottom Section - Job Lines */}
      <h2>Job Lines</h2>
      <div className="job-lines-list">
        {jobLines.length === 0 ? (
          <p>No job lines available.</p>
        ) : (
          jobLines.map((job, index) => {
            const timeSpent = calculateTotalTimeSpent(job.sessions);
            const formattedTimeSpent = formatTime(timeSpent);
            const estimatedTime = job.estimatedTime ? formatTime(job.estimatedTime * 3600000) : '0h00m'; // Convert hours to milliseconds for formatting
            
            return (
              <div key={index} className="job-line-item">
                <span className="problem" onClick={() => handleJobToggle(index)}>
                  <strong>Problem:</strong> {job.problem}
                </span>
                <span>{formattedTimeSpent}/{estimatedTime}</span>
                {job.estimate && (
                  <div>
                    <p>Suggested Time: {formatTime(job.suggestedTime * 3600000)}</p>
                    <button onClick={() => handleApproveTime(job.key, job.suggestedTime)}>
                      Approve Time
                    </button>
                  </div>
                )}
                {expandedJobIndex === index && (
                  <div className="job-details">
                    <p><strong>Total Balance:</strong> ${job.totalBalance?.toFixed(2)}</p>
                    <input
                      type="text"
                      value={job.cause || ''}
                      onChange={(e) => handleJobUpdate(job.key, 'cause', e.target.value)}
                      placeholder="Type your Cause Here"
                    />
                    <input
                      type="text"
                      value={job.correction || ''}
                      onChange={(e) => handleJobUpdate(job.key, 'correction', e.target.value)}
                      placeholder="Type your Correction Here"
                    />
                    <div className="photos-section">
                      {renderJobPhotos(job.photos)}
                    </div>
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>

      {/* Approve Ticket Button */}
      <button className="approve-ticket-btn" onClick={handleApproveTicket}>
        Approve Ticket
      </button>
    </div>
  );
};

export default ApprovalServiceTickets;
