import React, { useEffect, useState } from 'react';
import { database } from '../firebase';  // Import initialized Firebase database
import { ref, onValue } from 'firebase/database';  // Import ref and onValue from the modular SDK
import './CompaniesList.css';  // Add custom styles
import icTrust from '../images/ic_trust.png';  // Import your trust icon image
import icVerified from '../images/ic_verified.png';  // Import your verified icon image

const CompaniesList = () => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    console.log("Component mounted, attempting to fetch data from Firebase...");

    const companiesRef = ref(database, 'users/companies');

    onValue(companiesRef, (snapshot) => {
      const data = snapshot.val();
      console.log("Company data from Firebase:", data);

      if (data) {
        const companiesArray = Object.keys(data).map((key) => ({
          id: key,
          details: data[key].companyDetails,
          settings: data[key].companySettings, // Get company settings (trusted/verified)
        }));
        setCompanies(companiesArray);
      } else {
        setCompanies([]);
        console.log("No companies found.");
      }
    });
  }, []);

  return (
    <div className="companies-list-container">
  <h1 className="title">Registered Companies</h1>
  <ul className="companies-list">
    {companies.length > 0 ? (
      companies.map((company) => (
        <li key={company.id} className="company-card">
          {company.details ? (
            <>
              <img
                src={company.details.imageURL}
                alt={`${company.details.companyName} logo`}
                className="company-logo"
              />
              <div className="company-details">
                <h3>{company.details.companyName}</h3>
                <p>{company.details.companyAddress}</p>
                <p>{company.details.companyPhone}</p>
              </div>

              {/* Conditionally render trusted and verified icons inside the card */}
              <div className="company-status-icons">
                {company.settings?.trusted && (
                  <img src={icTrust} alt="Trusted" className="status-icon" />
                )}
                {company.settings?.verified && (
                  <img src={icVerified} alt="Verified" className="status-icon" />
                )}
              </div>
            </>
          ) : (
            <p>No company details available</p>
          )}
        </li>
      ))
    ) : (
      <p>No companies found.</p>
    )}
  </ul>

  {/* Add a row below the companies list to explain the icons */}
  <div className="companies-legend">
    <div className="legend-item">
      <img src={icTrust} alt="Trusted" className="legend-icon" />
      <span>*Trusted</span>
    </div>
    <div className="legend-item">
      <img src={icVerified} alt="Verified" className="legend-icon" />
      <span>*Verified</span>
    </div>
  </div>
</div>

  );
};

export default CompaniesList;
