import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { database } from '../firebase';
import { ref, set } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import ReactSlider from 'react-slider';
import { v4 as uuidv4 } from 'uuid';  // Import UUID generator

import './slider.css'; // Import custom CSS

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [latLng, setLatLng] = useState(null);
  const [laborRate, setLaborRate] = useState('');
  const [mobileRange, setMobileRange] = useState('');
  const [image, setImage] = useState(null); // Store the image
  const [imagePreview, setImagePreview] = useState(null); // Store image preview URL
  const [daysOfOperation, setDaysOfOperation] = useState([1, 5]);  // Default: Monday to Friday
  const [hoursOfOperation, setHoursOfOperation] = useState([8, 18]);  // Default: 8am to 6pm

  const handleSelect = async (address) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);
    setCompanyAddress(address);
    setLatLng(latLng);
  };
const handleRegister = async (e) => {
  e.preventDefault();

  const auth = getAuth();
  const storage = getStorage();

  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    let imageURL = null;

    if (image) {
      const imageRef = storageRef(storage, `company_images/${user.uid}`);
      const snapshot = await uploadBytes(imageRef, image);
      imageURL = await getDownloadURL(snapshot.ref);
    }

    const companyUUID = user.uid;  // Use the Firebase Auth UID as the companyUUID

    const companyRef = ref(database, `users/companies/${user.uid}`);
    await set(companyRef, {
      companyDetails: {
        companyName,
        companyPhone,
        companyAddress,
        coordinates: latLng,
        email: user.email,
        laborRate: laborRate || null,
        mobileRange: mobileRange || null,
        imageURL: imageURL || null,
        companyUUID,  // Save the user.uid as companyUUID
      },
      companySettings: {
        mobileBusiness: false,
        onTimeStatus: false,
        pendingStatus: true,
        trusted: false,
        verified: false,
        daysOfOperation,
        hoursOfOperation
      }
    });

    alert('Company registered successfully!');
  } catch (error) {
    console.error("Error during registration:", error);
    alert(error.message);
  }
};


  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      const selectedImage = e.target.files[0];
      setImage(selectedImage);
      setImagePreview(URL.createObjectURL(selectedImage)); // Set image preview
    }
  };

  const dayLabels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  return (
    <div className="register-container">
      <h2>Register Your Company</h2>
      <form onSubmit={handleRegister} className="register-form">
        <input
          type="text"
          placeholder="Company Name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          required
          className="form-input"
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="form-input"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="form-input"
        />
        <input
          type="text"
          placeholder="Company Phone"
          value={companyPhone}
          onChange={(e) => setCompanyPhone(e.target.value)}
          required
          className="form-input"
        />

        {/* Google Places Autocomplete for Address */}
        <PlacesAutocomplete
          value={companyAddress}
          onChange={setCompanyAddress}
          onSelect={handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: 'Search Company Address...',
                  className: 'form-input',
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        <input
          type="text"
          placeholder="Labor Rate (Optional)"
          value={laborRate}
          onChange={(e) => setLaborRate(e.target.value)}
          className="form-input"
        />
        <input
          type="text"
          placeholder="Mobile Range (Optional)"
          value={mobileRange}
          onChange={(e) => setMobileRange(e.target.value)}
          className="form-input"
        />

        {/* Days of Operation Slider */}
        <h3>Days of Operation</h3>
        <ReactSlider
          className="horizontal-slider"
          min={1}
          max={7}
          value={daysOfOperation}
          onChange={(value) => setDaysOfOperation(value)}
          thumbClassName="custom-thumb"
          trackClassName="custom-track"
          pearling
          minDistance={1}
          step={1}
        />
        <div className="slider-labels">
          {dayLabels.map((label, idx) => (
            <span key={idx} className="slider-label">{label}</span>
          ))}
        </div>

        {/* Hours of Operation Slider */}
        <h3>Hours of Operation</h3>
        <ReactSlider
          className="horizontal-slider"
          min={8}
          max={18}
          value={hoursOfOperation}
          onChange={(value) => setHoursOfOperation(value)}
          thumbClassName="custom-thumb"
          trackClassName="custom-track"
          pearling
          minDistance={1}
          step={1}
        />
        <div className="slider-labels">
          {['8 AM', '9 AM', '10 AM', '11 AM', '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM'].map((label, idx) => (
            <span key={idx} className="slider-label">{label}</span>
          ))}
        </div>

        {/* Image Upload Input and Preview */}
        <input
          type="file"
          onChange={handleImageChange}
          accept="image/*"
          className="form-input"
        />

        {/* Display Image Preview */}
        {imagePreview && (
          <div className="image-preview-container">
            <img src={imagePreview} alt="Selected" className="image-preview" />
          </div>
        )}

        <button type="submit" className="submit-button">Register</button>
      </form>
    </div>
  );
};

export default Register;
