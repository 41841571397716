import React, { useState, useEffect } from 'react';
import { database } from '../firebase'; 
import { ref, onValue } from 'firebase/database';
import './workHistory.css';  // Import the CSS file

const WorkHistory = () => {
  const [vin, setVin] = useState('');
  const [workHistory, setWorkHistory] = useState([]);
  const [message, setMessage] = useState('');

  const handleSearch = () => {
    const rvRef = ref(database, `users/RVPool/${vin}/workHistory`);

    onValue(rvRef, (snapshot) => {
      const workHistoryData = snapshot.val();

      if (workHistoryData) {
        const historyPromises = Object.keys(workHistoryData).map((ticketId) => {
          return new Promise((resolve) => {
            const ticketRef = ref(database, `users/RVPool/${vin}/workHistory/${ticketId}`);

            onValue(ticketRef, (ticketSnapshot) => {
              const ticketData = ticketSnapshot.val();
              let technicianName = "Not Available";
              if (ticketData.assignedTechs) {
                const firstTech = Object.values(ticketData.assignedTechs)[0];
                technicianName = `${firstTech.firstName} ${firstTech.lastName}`;
              }
              resolve({
                completedTimestamp: ticketData.completedTimestamp || 'N/A',
                assignedTechnician: technicianName,
                totalBalance: ticketData.TotalBalance || 'N/A'
              });
            });
          });
        });

        Promise.all(historyPromises).then((tickets) => {
          const sortedTickets = tickets.sort((a, b) => {
            const timeA = new Date(a.completedTimestamp).getTime();
            const timeB = new Date(b.completedTimestamp).getTime();
            return timeB - timeA;
          });

          setWorkHistory(sortedTickets);
          setMessage('');
        });
      } else {
        setMessage('Your RV is not Monitored by BluCollarBookings.');
        setWorkHistory([]);
      }
    });
  };

  return (
    <div className="work-history-container">
      <h2 className="work-history-header">Work History</h2>
      <div className="work-history-search">
        <input
          type="text"
          value={vin}
          onChange={(e) => setVin(e.target.value)}
          placeholder="Enter 17 digit VIN"
        />
        <button onClick={handleSearch}>Search</button>
      </div>
      {message && <p className="no-results-message">{message}</p>}
      <div className="work-history-list">
        {workHistory.length > 0 && (
          <h3>Work History for VIN: {vin}</h3>
        )}
        {workHistory.map((ticket, index) => (
          <div key={index} className="work-history-item">
            <p className="completed-timestamp">
              <strong>Completed Timestamp:</strong> {ticket.completedTimestamp}
            </p>
            <p><strong>Assigned Technician:</strong> {ticket.assignedTechnician}</p>
            <p className="total-balance"><strong>Total Balance:</strong> ${ticket.totalBalance}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkHistory;
