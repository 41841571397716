import React, { useState, useEffect } from 'react';
import { getDatabase, ref, set, onValue, remove, get } from 'firebase/database';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth'; // Firebase Auth for registration and login
import { useNavigate } from 'react-router-dom';
import './employeeRegistry.css';

const EmployeeRegistryForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');
  const [isInsured, setIsInsured] = useState(false);
  const [role, setRole] = useState('');
  const [employees, setEmployees] = useState([]);

  const auth = getAuth();
  const database = getDatabase();
  const navigate = useNavigate();

  useEffect(() => {
    const user = auth.currentUser;

    if (user) {
      const companyId = user.uid;
      const employeesRef = ref(database, `users/companies/${companyId}/Employees`);

      onValue(employeesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const employeeList = Object.entries(data).map(([id, employee]) => ({ id, ...employee }));
          setEmployees(employeeList);
        } else {
          setEmployees([]);
        }
      });
    }
  }, [auth, database]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;

    if (!user) {
      alert('You must be logged in as a company to register employees.');
      return;
    }

    const companyId = user.uid;

    try {
      // Fetch the companyUUID from the company's details in Firebase
      const companyRef = ref(database, `users/companies/${companyId}/companyDetails/companyUUID`);
      const snapshot = await get(companyRef);
      const companyUUID = snapshot.val();

      if (!companyUUID) {
        throw new Error('Company UUID not found.');
      }

      // Use Firebase Authentication to create a user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const employeeId = userCredential.user.uid;

      // After successful authentication, store additional employee information in Firebase Realtime Database
      const employeeData = {
        firstName,
        lastName,
        email,
        phone,
        licenseNumber,
        isInsured,
        roles: {
          isServiceWriter: role === 'serviceWriter',
          isTechnician: role === 'technician',
        },
        companyUUID // Save the company UUID for the employee
      };

      // Save employee data under the company node
      await set(ref(database, `users/companies/${companyId}/Employees/${employeeId}`), employeeData);

      alert('Employee registered successfully!');
      setFirstName('');
      setLastName('');
      setEmail('');
      setPassword('');  // Clear the password field
      setPhone('');
      setLicenseNumber('');
      setIsInsured(false);
      setRole('');  // Clear the role selection
    } catch (error) {
      console.error('Error registering employee:', error.message);
    }
  };

  const handleDelete = async (employeeId) => {
    const user = auth.currentUser;
    const companyId = user.uid;

    try {
      // Remove employee data from Firebase Realtime Database
      await remove(ref(database, `users/companies/${companyId}/Employees/${employeeId}`));
      alert('Employee deleted successfully.');
    } catch (error) {
      console.error('Error deleting employee:', error.message);
    }
  };

  // Employee login function using Firebase Authentication
  const loginEmployee = async (email, password) => {
    try {
      // Use Firebase Authentication for employee login
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('Login successful for employee:', userCredential.user.email);
    } catch (error) {
      console.error('Error logging in employee:', error.message);
    }
  };

  return (
    <div className="employee-registry-container">
      <h2>Register Employee</h2>
      <form onSubmit={handleSubmit} className="employee-form">
        <input
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
          className="form-input"
        />
        <input
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
          className="form-input"
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="form-input"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="form-input"
        />
        <input
          type="tel"
          placeholder="Phone Number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
          className="form-input"
        />
        <input
          type="text"
          placeholder="Driver's License Number"
          value={licenseNumber}
          onChange={(e) => setLicenseNumber(e.target.value)}
          required
          className="form-input"
        />
        <div className="insurance-checkbox">
          <label>Health Insurance:</label>
          <input
            type="checkbox"
            checked={isInsured}
            onChange={(e) => setIsInsured(e.target.checked)}
          />
        </div>
        <div className="form-input">
          <label>Role:</label>
          <div>
            <input
              type="radio"
              id="serviceWriter"
              name="role"
              value="serviceWriter"
              checked={role === 'serviceWriter'}
              onChange={(e) => setRole(e.target.value)}
            />
            <label htmlFor="serviceWriter">Service Writer</label>
          </div>
          <div>
            <input
              type="radio"
              id="technician"
              name="role"
              value="technician"
              checked={role === 'technician'}
              onChange={(e) => setRole(e.target.value)}
            />
            <label htmlFor="technician">Technician</label>
          </div>
        </div>
        <button type="submit" className="submit-button">Register Employee</button>
      </form>

      <h2>Current Employees</h2>
      <div className="employee-list">
        {employees.length === 0 ? (
          <p>No employees registered yet.</p>
        ) : (
          employees.map((employee) => (
            <div key={employee.id} className="employee-card">
              <p><strong>Name:</strong> {employee.firstName} {employee.lastName}</p>
              <p><strong>Email:</strong> {employee.email}</p>
              <p><strong>Phone:</strong> {employee.phone}</p>
              <p><strong>License Number:</strong> {employee.licenseNumber}</p>
              <p><strong>Insured:</strong> {employee.isInsured ? 'Yes' : 'No'}</p>
              <p><strong>Service Writer:</strong> {employee.roles.isServiceWriter ? 'Yes' : 'No'}</p>
              <p><strong>Technician:</strong> {employee.roles.isTechnician ? 'Yes' : 'No'}</p>
              <button onClick={() => handleDelete(employee.id)} className="delete-button">Delete</button>
            </div>
          ))
        )}
      </div>

      <button onClick={() => navigate('/dashboard')} className="back-button">Back to Dashboard</button>
    </div>
  );
};

export default EmployeeRegistryForm;
