import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import './login.css'; // Custom CSS for styling

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    setLoading(true); // Start loading when login process begins
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard');  // Redirect to the CompaniesDashboard after successful login
    } catch (error) {
      console.error('Error logging in:', error);
      alert(error.message);
    } finally {
      setLoading(false); // Stop loading after process is done (success or failure)
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleLogin} className="login-form">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="form-input"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="form-input"
        />
        <button type="submit" disabled={loading} className="submit-button">
          {loading ? 'Loading...' : 'Login'} {/* Display loading indicator */}
        </button>
      </form>

      {/* Register Button */}
      <div className="register-link">
        <p>Don't have an account?</p>
        <Link to="/register">
          <button className="register-button" disabled={loading}>Register</button> {/* Disable during loading */}
        </Link>
      </div>
    </div>
  );
};

export default Login;
