import React, { useState, useEffect } from 'react';
import { database } from '../firebase'; // Firebase import
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { ref, onValue } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import './dashboard.css'; // Custom CSS for styling

const CompaniesDashboard = () => {
  const [companyDetails, setCompanyDetails] = useState(null);
  const [employeeCount, setEmployeeCount] = useState(0); // State for employee count
  const [pendingTickets, setPendingTickets] = useState([]); // State for pending tickets
  const [activeTickets, setActiveTickets] = useState([]); // State for active tickets
  const [approvalTickets, setApprovalTickets] = useState([]); // State for approval tickets
  const [completedTickets, setCompletedTickets] = useState([]); // State for completed tickets
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanyDetails = () => {
      const user = auth.currentUser;

      if (user) {
        const companyRef = ref(database, `users/companies/${user.uid}/companyDetails`);
        onValue(companyRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            setCompanyDetails(data); // Store company details
          } else {
            console.log("No company details found for the user.");
          }
          setLoading(false);
        });

        // Fetch employee count
        const employeesRef = ref(database, `users/companies/${user.uid}/Employees`);
        onValue(employeesRef, (snapshot) => {
          const employeeData = snapshot.val();
          if (employeeData) {
            setEmployeeCount(Object.keys(employeeData).length); // Count the number of employees
          } else {
            setEmployeeCount(0); // No employees found
          }
        });

        // Fetch pending service tickets
        const pendingTicketsRef = ref(database, `users/companies/${user.uid}/ticketManager/pendingServiceTickets`);
        onValue(pendingTicketsRef, (snapshot) => {
          const ticketsData = snapshot.val();
          if (ticketsData) {
            const ticketsWithCustomerNames = Object.entries(ticketsData).map(([ticketID, ticketData]) => {
              const customerName = `${ticketData.customerDetails.lastName}, ${ticketData.customerDetails.firstName}`;
              return { ticketID, customerName }; // Create an object with ticketID and customerName
            });
            setPendingTickets(ticketsWithCustomerNames);
          } else {
            setPendingTickets([]);
          }
        });

        // Fetch active service tickets
        const activeTicketsRef = ref(database, `users/companies/${user.uid}/ticketManager/activeServiceTickets`);
        onValue(activeTicketsRef, (snapshot) => {
          const activeTicketsData = snapshot.val();
          if (activeTicketsData) {
            const ticketsWithCustomerNames = Object.entries(activeTicketsData).map(([ticketID, ticketData]) => {
              const customerName = `${ticketData.customerDetails.lastName}, ${ticketData.customerDetails.firstName}`;
              return { ticketID, customerName }; // Create an object with ticketID and customerName
            });
            setActiveTickets(ticketsWithCustomerNames);
          } else {
            setActiveTickets([]);
          }
        });

        // Fetch approval service tickets
        const approvalTicketsRef = ref(database, `users/companies/${user.uid}/ticketManager/approvalServiceTickets`);
        onValue(approvalTicketsRef, (snapshot) => {
          const approvalTicketsData = snapshot.val();
          if (approvalTicketsData) {
            const ticketsWithCustomerNames = Object.entries(approvalTicketsData).map(([ticketID, ticketData]) => {
              const customerName = `${ticketData.customerDetails.lastName}, ${ticketData.customerDetails.firstName}`;
              return { ticketID, customerName }; // Create an object with ticketID and customerName
            });
            setApprovalTickets(ticketsWithCustomerNames);
          } else {
            setApprovalTickets([]);
          }
        });

        // Fetch completed service tickets
        const completedTicketsRef = ref(database, `users/companies/${user.uid}/ticketManager/completedServiceTickets`);
        onValue(completedTicketsRef, (snapshot) => {
          const completedTicketsData = snapshot.val();
          if (completedTicketsData) {
            const ticketsWithCustomerNames = Object.entries(completedTicketsData).map(([ticketID, ticketData]) => {
              const customerName = `${ticketData.customerDetails.lastName}, ${ticketData.customerDetails.firstName}`;
              return { ticketID, customerName }; // Create an object with ticketID and customerName
            });
            setCompletedTickets(ticketsWithCustomerNames);
          } else {
            setCompletedTickets([]);
          }
        });
      } else {
        console.log("User not logged in");
        setLoading(false);
      }
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchCompanyDetails();
      } else {
        console.log("No user is logged in.");
      }
    });
  }, [auth]);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/'); // Redirect to homepage after logout
      })
      .catch((error) => {
        console.error('Error logging out:', error);
      });
  };

  // Function to navigate to approval service ticket
  const handleViewApprovalTicket = (ticketID) => {
    navigate(`/approval-service-ticket/${ticketID}`); // Navigate to ApprovalServiceTicket.js
  };

  const handleViewCompletedTicket = (ticketID) => {
    navigate(`/completed-service-ticket/${ticketID}`); // Navigate to CompletedServiceTicket.js
  };

  const handleAddEmployee = () => {
    navigate('/register-employee');
  };

  const handleCreateTicket = () => {
    navigate('/create-ticket');
  };

  const handleViewTicket = (ticketID) => {
    navigate(`/service-ticket/${ticketID}`); // Navigate to ServiceTicket.js with ticket ID
  };

  if (loading) {
    return <div className="loading-container">Loading company details...</div>;
  }

  if (!companyDetails) {
    return <div className="loading-container">No company details found for this user.</div>;
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-grid">
        {/* Company Information Section */}
        <div className="company-info">
          <h2 className="company-name">{companyDetails.companyName}</h2>
          <p><strong>Address:</strong> {companyDetails.companyAddress}</p>
          <p><strong>Phone:</strong> {companyDetails.companyPhone}</p>
          <p><strong>Email:</strong> {companyDetails.email}</p>
          <p><strong>Number of Employees:</strong> {employeeCount}</p>
          <button onClick={handleAddEmployee} className="add-employee-button">Add Employee</button>
        </div>

        {/* Pending Tickets Section */}
        <div className="tickets-container">
          <h2 className="section-title">Pending Service Tickets</h2>
          {pendingTickets.length === 0 ? (
            <p>No pending service tickets.</p>
          ) : (
            pendingTickets.map(({ ticketID, customerName }) => (
              <div key={ticketID} className="ticket-card">
                <h3>{customerName}</h3>
              </div>
            ))
          )}
        </div>

        {/* Active Tickets Section (Scrollable with only ticket numbers) */}
        <div className="active-tickets-container">
          <h2 className="section-title">Active Service Tickets</h2>
          <div className="ticket-list">
            {activeTickets.length === 0 ? (
              <p>No active service tickets.</p>
            ) : (
              activeTickets.map(({ ticketID, customerName }) => (
                <div
                  key={ticketID}
                  className="ticket-card"
                  onClick={() => handleViewTicket(ticketID)} // Navigate on click
                >
                  <h3>{customerName}</h3>
                </div>
              ))
            )}
          </div>
        </div>

        {/* Approval Tickets Section */}
        <div className="approval-tickets-container">
          <h2 className="section-title">Approval Service Tickets</h2>
          <div className="ticket-list">
            {approvalTickets.length === 0 ? (
              <p>No approval service tickets.</p>
            ) : (
              approvalTickets.map(({ ticketID, customerName }) => (
                <div
                  key={ticketID}
                  className="ticket-card"
                  onClick={() => handleViewApprovalTicket(ticketID)} // Navigate to approval ticket
                >
                  <h3>{customerName}</h3>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      {/* Completed Tickets Section */}
      <div className="completed-tickets-container" style={{ marginTop: '20px' }}>
        <h2 className="section-title">Completed Service Tickets</h2>
        <div className="ticket-list">
          {completedTickets.length === 0 ? (
            <p>No completed service tickets.</p>
          ) : (
            completedTickets.map(({ ticketID, customerName }) => (
              <div
                key={ticketID}
                className="ticket-card"
                onClick={() => handleViewCompletedTicket(ticketID)} // Navigate to completed ticket
              >
                <h3>{customerName}</h3>
              </div>
            ))
          )}
        </div>
      </div>

      <button onClick={handleCreateTicket} className="create-ticket-button">
        Create Ticket
      </button>

      <button onClick={handleLogout} className="logout-button">
        Logout
      </button>
    </div>
  );
};

export default CompaniesDashboard;
