import React, { useState, useEffect } from 'react';
import { database } from '../firebase';
import { ref, onValue, set, push, update, remove } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { useParams } from 'react-router-dom';
import './serviceTicket.css'; // Custom CSS

const ServiceTicket = () => {
  const { ticketId } = useParams(); // Retrieve ticketId from the URL
  const [parts, setParts] = useState([]);
  const [jobLines, setJobLines] = useState([]);
  const [quantity, setQuantity] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [totalPartsAmount, setTotalPartsAmount] = useState(0.0);
  const [shopSupplies, setShopSupplies] = useState(0.0);
  const [expandedJobIndex, setExpandedJobIndex] = useState(null); // Track expanded job index
  const [customerDetails, setCustomerDetails] = useState(null);
  const [rvDetails, setRvDetails] = useState(null);
  const [technicians, setTechnicians] = useState([]);
  const [selectedTechnician, setSelectedTechnician] = useState('');
  const [assignedTechnician, setAssignedTechnician] = useState('');

  const auth = getAuth();
  const [companyId, setCompanyId] = useState('');

  // Fetch companyUUID based on the logged-in user's UID
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userCompanyIdRef = ref(database, `users/companies/${user.uid}/companyDetails/companyUUID`);
      onValue(userCompanyIdRef, (snapshot) => {
        const fetchedCompanyId = snapshot.val();
        setCompanyId(fetchedCompanyId);
      });
    }
  }, [auth]);

  // Fetch parts, job lines, customer, RV details, and technicians for the specific ticket
  useEffect(() => {
    if (companyId && ticketId) {
      const partsRef = ref(database, `users/companies/${companyId}/ticketManager/activeServiceTickets/${ticketId}/Parts`);
      onValue(partsRef, (snapshot) => {
        const partsData = [];
        let total = 0;

        snapshot.forEach((childSnapshot) => {
          const partKey = childSnapshot.key;
          const part = childSnapshot.val();

          // Exclude entries for shopSupplies and totalPartsAmount, only push actual parts
          if (partKey !== 'ShopSupplies' && partKey !== 'TotalPartsAmount') {
            if (part.perPartAmount && part.quantity) {
              total += part.perPartAmount * part.quantity;
            }
            partsData.push(part);
          }
        });

        setParts(partsData);
        setTotalPartsAmount(total);
        setShopSupplies(total * 0.1);  // 10% of the total amount
      });

      const jobLinesRef = ref(database, `users/companies/${companyId}/ticketManager/activeServiceTickets/${ticketId}/Jobs`);
      onValue(jobLinesRef, (snapshot) => {
        const jobLinesData = [];
        snapshot.forEach((childSnapshot) => {
          const jobLine = { key: childSnapshot.key, ...childSnapshot.val() }; // Store key for updates
          jobLinesData.push(jobLine);
        });
        setJobLines(jobLinesData);
      });

      // Fetch customer and RV details
      const customerRef = ref(database, `users/companies/${companyId}/ticketManager/activeServiceTickets/${ticketId}/customerDetails`);
      onValue(customerRef, (snapshot) => {
        setCustomerDetails(snapshot.val());
      });

      const rvRef = ref(database, `users/companies/${companyId}/ticketManager/activeServiceTickets/${ticketId}/customerRV`);
      onValue(rvRef, (snapshot) => {
        setRvDetails(snapshot.val());
      });

      // Fetch technicians
      const employeesRef = ref(database, `users/companies/${companyId}/Employees`);
      onValue(employeesRef, (snapshot) => {
        const technicianList = [];
        snapshot.forEach((childSnapshot) => {
          const employee = childSnapshot.val();
          if (employee.roles && employee.roles.isTechnician) {
            technicianList.push({ id: childSnapshot.key, ...employee });
          }
        });
        setTechnicians(technicianList);
      });

      // Fetch assigned technician
      const ticketRef = ref(database, `users/companies/${companyId}/ticketManager/activeServiceTickets/${ticketId}`);
      onValue(ticketRef, (snapshot) => {
        const ticketData = snapshot.val();
        if (ticketData?.assignedTechnician) {
          setAssignedTechnician(ticketData.assignedTechnician);
        }
      });
    }
  }, [companyId, ticketId]);

  // Assign a technician to the ticket
  const handleAssignTechnician = () => {
    if (!selectedTechnician || !companyId || !ticketId) {
      alert("Please select a technician.");
      return;
    }

    const ticketRef = ref(database, `users/companies/${companyId}/ticketManager/activeServiceTickets/${ticketId}`);
    const assignedTechsRef = ref(database, `users/companies/${companyId}/ticketManager/activeServiceTickets/${ticketId}/assignedTechs`);

    // Get current date for directory name and formatted timestamp
    const now = new Date();
    
    // Format date for directory as YYYY-MM-DD
    const dateKey = now.toISOString().slice(0, 10); // 'YYYY-MM-DD' format
    
    // Format assignedAt as DD-MM-YYYY HH:MM (without military time)
    const formatDate = (date) => {
      let hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const isPM = hours >= 12;
      hours = hours % 12 || 12; // Convert to 12-hour format
      const ampm = isPM ? 'PM' : 'AM';
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = date.getFullYear();
      return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
    };

    const assignedAt = formatDate(now);

    // Fetch the technician's details
    const technicianDetails = technicians.find(tech => tech.id === selectedTechnician);

    if (technicianDetails) {
      // Save assigned technician info in the ticket
      update(ticketRef, {
        assignedTechnician: selectedTechnician  // Save the technician's UUID in the ticket
      }).then(() => {
        // Create a new directory for assigned technician details with just the date as the key
        const newTechRef = ref(database, `users/companies/${companyId}/ticketManager/activeServiceTickets/${ticketId}/assignedTechs/${dateKey}`);
        set(newTechRef, {
          uuid: selectedTechnician,
          firstName: technicianDetails.firstName,
          lastName: technicianDetails.lastName,
          assignedAt: assignedAt // Use the formatted date
        }).then(() => {
          alert("Technician assigned successfully.");
        }).catch((error) => {
          console.error("Error saving technician info:", error);
        });
      }).catch((error) => {
        console.error("Error assigning technician:", error);
      });
    }
  };

  // Release the assigned technician
  const handleReleaseTechnician = () => {
    if (!companyId || !ticketId) return;

    const ticketRef = ref(database, `users/companies/${companyId}/ticketManager/activeServiceTickets/${ticketId}/assignedTechnician`);
    
    // Remove the assigned technician field
    remove(ticketRef).then(() => {
      alert("Technician released successfully.");
      setAssignedTechnician('');  // Clear the UI
    }).catch((error) => {
      console.error("Error releasing technician:", error);
    });
  };

  // Add a new part to the selected ticket
  const handleAddPart = () => {
    if (!companyId || !ticketId) return;

    const validQuantity = parseFloat(quantity);
    const validPrice = parseFloat(price);
    if (isNaN(validQuantity) || isNaN(validPrice) || validQuantity <= 0 || validPrice <= 0) return;

    const newPart = { quantity: validQuantity, description, perPartAmount: validPrice, totalAmount: validQuantity * validPrice };

    const partsRef = ref(database, `users/companies/${companyId}/ticketManager/activeServiceTickets/${ticketId}/Parts`);
    const newPartRef = push(partsRef);
    set(newPartRef, newPart).then(() => {
      setQuantity('');
      setDescription('');
      setPrice('');
    });

    // Save ShopSupplies and TotalPartsAmount inside the Parts directory
    update(partsRef, {
      TotalPartsAmount: totalPartsAmount + validQuantity * validPrice,
      ShopSupplies: (totalPartsAmount + validQuantity * validPrice) * 0.1,
    });
  };

  // Calculate total time spent across sessions
  const calculateTotalTimeSpent = (sessions) => {
    let totalTimeMillis = 0;
    if (sessions) {
      Object.values(sessions).forEach((session) => {
        const clockInTime = session.clockInTime || 0;
        const clockOutTime = session.clockOutTime || 0;
        totalTimeMillis += (clockOutTime - clockInTime);
      });
    }
    const totalMinutes = totalTimeMillis / (1000 * 60);
    return totalMinutes.toFixed(2); // Return total time in minutes
  };

  // Display job photos
  const renderJobPhotos = (photos) => {
    if (!photos) return null;

    return Object.entries(photos).map(([key, photoUrl]) => (
      <img
        key={key}
        src={photoUrl}
        alt="Job Photo"
        style={{ width: '100px', height: '100px', marginRight: '10px', borderRadius: '8px' }}
      />
    ));
  };

  // Toggle job line expansion to edit cause and correction
  const handleJobToggle = (index) => {
    setExpandedJobIndex(expandedJobIndex === index ? null : index);
  };

  // Function to handle real-time updates for Cause and Correction in Firebase
  const handleJobUpdate = (jobKey, field, value) => {
    const jobRef = ref(database, `users/companies/${companyId}/ticketManager/activeServiceTickets/${ticketId}/Jobs/${jobKey}`);
    update(jobRef, { [field]: value }).catch((error) => {
      console.error("Error updating job line:", error);
    });
  };

  // Complete Ticket Function
  const handleCompleteTicket = () => {
    if (!companyId || !ticketId || !rvDetails?.vin) return;

    const activeTicketRef = ref(database, `users/companies/${companyId}/ticketManager/activeServiceTickets/${ticketId}`);
    const completedTicketRef = ref(database, `users/companies/${companyId}/ticketManager/completedServiceTickets/${ticketId}`);

    // Fetch the active ticket data
    onValue(activeTicketRef, (snapshot) => {
      const ticketData = snapshot.val();
      
      if (ticketData) {
        // Move the ticket to completedServiceTickets
        set(completedTicketRef, ticketData)
          .then(() => {
            // Remove ticket from activeServiceTickets
            remove(activeTicketRef)
              .then(() => {
                console.log('Ticket successfully moved to completed.');
              })
              .catch((error) => {
                console.error('Error removing from activeServiceTickets:', error);
              });
          })
          .catch((error) => {
            console.error('Error saving to completedServiceTickets:', error);
          });
      }
    }, {
      onlyOnce: true
    });
  };

  return (
    <div className="service-ticket-container">
      <h2 className="service-ticket-id">Ticket #{ticketId}</h2>

      {/* Assigned Technician */}
      <div className="service-ticket-assigned-technician-container">
        <div className="service-ticket-assigned-technician">
          <h3>Assigned Technician:</h3>
          {assignedTechnician ? (
            <>
              <p>{technicians.find(tech => tech.id === assignedTechnician)?.firstName || 'Unknown Technician'}</p>
              <button onClick={handleReleaseTechnician}>Release Technician</button>
            </>
          ) : (
            <p>No technician assigned yet.</p>
          )}
        </div>

        {/* Technician Assignment Dropdown */}
        <div className="service-ticket-technician-selection">
          <select
            value={selectedTechnician}
            onChange={(e) => setSelectedTechnician(e.target.value)}
          >
            <option value="">Select Technician</option>
            {technicians.map((technician) => (
              <option key={technician.id} value={technician.id}>
                {technician.firstName} {technician.lastName}
              </option>
            ))}
          </select>
          <button className="service-ticket-button" onClick={handleAssignTechnician}>
            Assign Technician
          </button>
        </div>
      </div>

      {/* Top Section: Customer and RV Information */}
      <div className="service-ticket-content-container">
        {/* Left Side: Customer Information */}
        <div className="service-ticket-left-container">
          <h3>Customer Information</h3>
          {customerDetails ? (
            <div>
              <p><strong>Name:</strong> {customerDetails.firstName} {customerDetails.lastName}</p>
              <p><strong>Email:</strong> {customerDetails.email}</p>
              <p><strong>Phone:</strong> {customerDetails.phone}</p>
              <p><strong>Address:</strong> {customerDetails.address}</p>
              <img src={customerDetails.profileImageUrl} alt="Customer Profile" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
            </div>
          ) : (
            <p>Loading customer details...</p>
          )}
        </div>

        {/* Right Side: RV Information */}
        <div className="service-ticket-right-container">
          <h3>RV Information</h3>
          {rvDetails ? (
            <div>
              <p><strong>Make:</strong> {rvDetails.make}</p>
              <p><strong>Model:</strong> {rvDetails.model}</p>
              <p><strong>Year:</strong> {rvDetails.year}</p>
              <p><strong>VIN:</strong> {rvDetails.vin}</p>
              <p><strong>Axle Amount:</strong> {rvDetails.axleAmount}</p>
              <img src={rvDetails.rvImage} alt="RV" style={{ width: '150px', height: '100px', borderRadius: '8px' }} />
            </div>
          ) : (
            <p>Loading RV details...</p>
          )}
        </div>
      </div>

      {/* Remaining content like Parts and Job Lines */}
      <div className="service-ticket-content-container">
        {/* Left Side - Add Part Form */}
        <div className="service-ticket-left-container">
          <h3>Add New Part</h3>
          <input
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            placeholder="Quantity"
          />
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
          />
          <input
            type="number"
            step="0.01"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeholder="Per Item Price"
          />
          <button className="service-ticket-button" onClick={handleAddPart}>Add Part</button>

          <div className="service-ticket-totals-section">
            <h3>Shop Supplies: ${shopSupplies.toFixed(2)}</h3>
            <h3>Total Parts Amount: ${totalPartsAmount.toFixed(2)}</h3>
          </div>
        </div>

        {/* Right Side - Parts List */}
        <div className="service-ticket-right-container">
          <h3>Parts for Ticket #{ticketId}</h3>
          <div className="service-ticket-parts-list">
            {parts.length === 0 ? <p>No parts added yet.</p> : (
              parts.map((part, index) => (
                <div key={index} className="service-ticket-part-item">
                  <span><strong>Qty:</strong> {part.quantity}</span>
                  <span><strong>Description:</strong> {part.description}</span>
                  <span><strong>Per Item Price:</strong> ${part.perPartAmount?.toFixed(2)}</span>
                  <span><strong>Total Amount:</strong> ${part.totalAmount?.toFixed(2)}</span>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      {/* Bottom Section - Job Lines */}
      <h2>Job Lines</h2>
      <div className="service-ticket-job-lines-list">
        {jobLines.length === 0 ? (
          <p>No job lines available.</p>
        ) : (
          jobLines.map((job, index) => (
            <div key={index} className="service-ticket-job-line-item">
              <span className="problem" onClick={() => handleJobToggle(index)}>
                <strong>Problem:</strong> {job.problem}
              </span>
              {expandedJobIndex === index && (
                <div className="service-ticket-job-details">
                  <p><strong>Total Time Spent:</strong> {calculateTotalTimeSpent(job.sessions)} minutes</p>
                  <p><strong>Total Balance:</strong> ${job.totalBalance?.toFixed(2)}</p>
                  <p><strong>Cause:</strong> {job.cause || 'N/A'}</p>
                  <p><strong>Correction:</strong> {job.correction || 'N/A'}</p>
                  <div className="photos-section">
                    {renderJobPhotos(job.photos)}
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>

      {/* Complete Ticket Button */}
      <button className="service-ticket-button" onClick={handleCompleteTicket}>
        Finalize Ticket
      </button>
    </div>
  );
};

export default ServiceTicket;
