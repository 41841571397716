// Import the functions you need from the Firebase SDK
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCKc4Ui9Vect8Ht1-1GwtY3Vd6mLLFugg0",
  authDomain: "rvautoservice-5e86a.firebaseapp.com",
  databaseURL: "https://rvautoservice-5e86a-default-rtdb.firebaseio.com",
  projectId: "rvautoservice-5e86a",
  storageBucket: "rvautoservice-5e86a.appspot.com",
  messagingSenderId: "155805770590",
  appId: "1:155805770590:android:38e7183778bdc308ed25cf" // You should replace this with your actual App ID from the Firebase Console
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Realtime Database and get a reference to the service
const database = getDatabase(firebaseApp);

export { database };
